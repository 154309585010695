// Center UI Colors
export default {
  midnight: "var(--cen-colors-text-500)",
  text: "var(--cen-colors-text-500)",
  primary: "var(--cen-colors-primary-500)",
  navy_alpha_40: "#001c3d66", // navy @ 40% opacity
  dark_gray: "#6C7580",
  monumental: "#849099",
  secondary: "var(--cen-colors-secondary-500)",
  placeholder_gray: "#b9c4d3",
  cool_gray: "#e4e7ed",
  fog_gray: "#e6e6e6",
  lunar_gray: "#f0f0f0",
  bg_gray: "#f8f8fa",
  splits_gray: "#f1f1f5",
  sky: "#F0F5FE",
  danger_red: "#AF2712",
  success_green: "#008C73",
  center_yellow: "#fdb913",
  highlight_yellow: "rgba(253, 185, 19, 0.5)",
  baby_blue: "#b5c7d3",
  link_blue: "var(--cen-colors-link-500)",
  link: "var(--cen-colors-link-500)",
  river_blue: "#0F2F5D",
  classic_blue: "#0F4C81", // aka denim
  sidecar_blue: "rgba(15, 76, 129, 0.04)", // #0f4c81 @ 4% opacity
  providence_blue: "#658DC6",
  sky_blue: "#1763E5",
  white: "#ffffff",
  neutral: "#BFBFBF",
  eggplant_purple: "#383559",
  passion_purple: "#676BB0",
  royal: "var(--cen-colors-link-500)",
  vintage_maroon: "#621D1D", // aka mulberry
  sunset_red: "#D34A34",
  salmon: "#D77565",
  light_red: "#E9CFD4",
  light_green: "rgba(0, 140, 115, 0.1)",
  light_blue: "rgba(23, 99, 229, 0.1)",
  bayberry_green: "#255958",
  jade_green: "#008C73",
  surf_green: "#5E9693",
  medium_gray: "#838789",
};
