import { Fragment, useState } from "react";
import PropTypes from "prop-types";

import Confirm from "../Confirm";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";
import { CardProgramType } from "./CardProgramType";

export default function CardProgram(props) {
  const { show, orgID, cardPrograms, addCardProgram, deleteCardProgram } = props;
  const [state, setState] = useState({
    confirm: "",
    loading: false,
    error: false,
    done: false,
    accountCode: "",
    customerID: "",
    defaultLimitProfile: "",
    removeProgram: null,
    cardPrograms,
  });

  const validate = ({ accountCode, customerID, defaultLimitProfile }) => {
    const errors = [];

    const acValid = /[A-Z]-[0-9]+/.test(accountCode);
    const ciValid = /^[A-Z0-9]{5}$/.test(customerID);
    const dlpValid = /^[\d]{3}$/.test(defaultLimitProfile);

    // allow any values for TEST orgs
    if (customerID.startsWith("TEST-")) {
      return [];
    }

    if (!acValid) {
      errors.push(`Invalid Account Code Format: ${accountCode}.`);
    }

    if (!ciValid) {
      errors.push(`Invalid Customer ID Format: ${customerID}.`);
    }

    if (defaultLimitProfile && !dlpValid) {
      errors.push(`Invalid Default Limit Profile Format: ${defaultLimitProfile}.`);
    }

    return errors;
  };

  const requestDelete = (cardProgramID) => {
    const confirm = [
      "Are you sure you want to remove ",
      <b>{cardProgramID}</b>,
      " Card Program of ",
      <b>{orgID}</b>,
      " Organization?",
    ];

    return setState({ ...state, confirm, removeProgram: cardProgramID });
  };

  const onDelete = (cardProgramID) => async (e) => {
    e.preventDefault();

    if (cardProgramID) {
      return requestDelete(cardProgramID);
    }

    return null;
  };

  const onCreate = async (e) => {
    e.preventDefault();

    const programs = state.cardPrograms;

    if (programs.length >= 10) {
      setState({ ...state, error: "Reached limit of 10 programs per org" });
      return;
    }

    const { accountCode, customerID, defaultLimitProfile } = state;
    const errors = validate({ accountCode, customerID, defaultLimitProfile });

    if (errors.length) {
      setState({ ...state, error: errors.join("\n") });
      return;
    }

    setState({ ...state, loading: true });

    const result = await addCardProgram(
      orgID,
      {
        accountCode: accountCode.trim(),
        customerID: customerID.trim(),
      },
      defaultLimitProfile,
    );
    if (result && result.ID) {
      setState({
        ...state,
        loading: false,
        error: false,
        accountCode: "",
        customerID: "",
        defaultLimitProfile: "",
        cardPrograms: programs,
        done: "Card Program added",
      });
      return;
    }

    const errorMessage = result && result.errorMessage;
    setState({ ...state, error: errorMessage, loading: false });
  };

  const doDelete = async (e) => {
    e.preventDefault();
    const cardProgramID = state.removeProgram;
    setState({ ...state, loading: true, confirm: "" });

    const result = await deleteCardProgram(orgID, cardProgramID);

    const Progs = state.cardPrograms.filter((prog) => prog.ID !== cardProgramID);
    if (!result || !result.errorMessage) {
      setState({
        ...state,
        confirm: "",
        loading: false,
        error: false,
        accountCode: "",
        customerID: "",
        removeProgram: null,
        cardPrograms: Progs,
        done: `Card Program ${cardProgramID} removed`,
      });

      return;
    }

    const errorMessage = result && result.errorMessage;
    setState({ ...state, error: errorMessage, loading: false });
  };

  const renderPrograms = (programs = []) => (
    <div className="card-programs-list">
      <p>Card Programs</p>
      {programs
        .filter((p) => p.comdataID)
        .map((program) => (
          <Fragment key={program.ID}>
            <div className="card-programs-list-item">
              <div className="data-input">
                <label htmlFor="add-card-program-code">
                  Account Code
                  <input type="text" readOnly value={program.comdataID?.accountCode} />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor="add-card-program-id">
                  Customer ID
                  <input type="text" readOnly value={program.comdataID?.customerID} />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor="add-default-limit-profile">
                  Default Limit Profile
                  <input type="text" readOnly value={program.defaultLimitProfile} />
                </label>
              </div>
              <div className="data-input submit">
                <button type="submit" onClick={onDelete(program.ID)}>
                  Remove Card Program
                </button>
              </div>
            </div>
            <CardProgramType
              isPrefunded={program.isPrefunded}
              programID={program.ID}
              overwrittenAccFunding={program.overwrittenAccFunding}
              orgID={orgID}
            />
          </Fragment>
        ))}
    </div>
  );

  if (!show) {
    return "";
  }

  const { loading, accountCode, customerID, defaultLimitProfile, error, done } = state;

  return (
    <Loader loading={loading}>
      <Confirm
        message={state.confirm}
        onCancel={() => setState({ ...state, confirm: "" })}
        onConfirm={doDelete}
      />
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      <div className="card-program">
        <p>Add Card Program</p>
        <form onSubmit={onCreate} autoComplete="off">
          <div className="data-input">
            <label htmlFor="add-card-program-code">
              Account Code *
              <input
                id="add-card-program-code"
                type="text"
                required
                placeholder="A-123"
                value={accountCode}
                onChange={(e) => setState({ ...state, accountCode: e.target.value })}
              />
            </label>
          </div>
          <div className="data-input">
            <label htmlFor="add-card-program-id">
              Customer ID *
              <input
                id="add-card-program-id"
                type="text"
                required
                placeholder="ABCDE"
                value={customerID}
                onChange={(e) => setState({ ...state, customerID: e.target.value })}
              />
            </label>
          </div>
          <div className="data-input">
            <label htmlFor="add-default-limit-profile">
              Default Limit Profile
              <input
                id="add-default-limit-profile"
                type="text"
                placeholder="000"
                maxLength="3"
                value={defaultLimitProfile}
                onChange={(e) =>
                  setState({
                    ...state,
                    defaultLimitProfile: e.target.value.replace(/[^\d.]/gi, ""),
                  })
                }
              />
            </label>
          </div>
          <div className="data-input submit">
            <button type="submit" disabled={state.cardPrograms.length >= 10}>
              Add Card Program
            </button>
          </div>
        </form>
      </div>
      {renderPrograms(state.cardPrograms)}
    </Loader>
  );
}

CardProgram.propTypes = {
  show: PropTypes.bool,
  orgID: PropTypes.string,
  cardPrograms: PropTypes.array,
  addCardProgram: PropTypes.func.isRequired,
  deleteCardProgram: PropTypes.func.isRequired,
};
