import PropTypes from "prop-types";

import { NEW_ORG_NEW_TMC } from "./TravelManagementCompanies";

export default function TravelContracts(props) {
  const { partner, partnerID, changeContractData, deleteContract, travelOrgValue } = props;

  return (
    <div>
      {partner?.emergencyContacts?.length && travelOrgValue?.[partnerID] === NEW_ORG_NEW_TMC
        ? partner?.emergencyContacts.map((contact, id) => (
            <div
              id={`emergency-contract-block-${id}`}
              key={`emergency-contract-block-${id}`}
              className="partner-block contract-block"
            >
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-name-${id}`}>
                  First Name*
                  <input
                    id={`new-tmc-contact-name-${id}`}
                    type="text"
                    value={contact.firstName}
                    required
                    onChange={(e) => {
                      changeContractData(partnerID, id, "firstName", e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-mname-${id}`}>
                  Middle Name
                  <input
                    id={`new-tmc-contact-mname-${id}`}
                    type="text"
                    value={contact.middleName}
                    onChange={(e) => {
                      changeContractData(partnerID, id, "middleName", e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-lname-${id}`}>
                  Last Name*
                  <input
                    id={`new-tmc-contact-lname-${id}`}
                    type="text"
                    required
                    value={contact.lastName}
                    onChange={(e) => {
                      changeContractData(partnerID, id, "lastName", e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-designation-${id}`}>
                  Designation*
                  <input
                    id={`new-tmc-contact-designation-${id}`}
                    type="text"
                    required
                    value={contact.designation}
                    onChange={(e) => {
                      changeContractData(partnerID, id, "designation", e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-email-${id}`}>
                  Email*
                  <input
                    id={`new-tmc-contact-email-${id}`}
                    type="email"
                    required
                    value={contact.email}
                    onChange={(e) => {
                      changeContractData(partnerID, id, "email", e.target.value);
                    }}
                  />
                </label>
              </div>
              <div className="data-input">
                <label htmlFor={`new-tmc-contact-email-${id}`}>
                  Phone*
                  <input
                    id={`new-tmc-contact-phone-${id}`}
                    type="phone"
                    placeholder="Phone with Country Code without +"
                    required
                    pattern="^[0-9]{11}"
                    value={contact.phone}
                    onChange={(e) => {
                      changeContractData(partnerID, id, "phone", e.target.value);
                    }}
                  />
                </label>
              </div>
              <button
                id="btn-remove-contract-tmc"
                className="btn-remove-tmc delete-contract-btn"
                type="button"
                onClick={() => {
                  deleteContract(partnerID, id);
                }}
              >
                X
              </button>
            </div>
          ))
        : null}
    </div>
  );
}

TravelContracts.propTypes = {
  partner: PropTypes.object,
  partnerID: PropTypes.number,
  changeContractData: PropTypes.func,
  deleteContract: PropTypes.func,
};
