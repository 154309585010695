import authZeroIcon from "@iconify/icons-simple-icons/auth0";
import { Icon } from "@iconify/react";
import { Card, Typography } from "@material-ui/core";
import { alpha, styled } from "@material-ui/core/styles";

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24,
  )} 100%)`,
}));

export default ({ status }) => (
  <RootStyle>
    <IconWrapperStyle>
      <Icon icon={authZeroIcon} width={24} height={24} />
    </IconWrapperStyle>
    <Typography variant="h3">Comdata</Typography>
    <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
      {status}
    </Typography>
  </RootStyle>
);
