import { useEffect, useState } from "react";

import { getToken } from "../../services/api";
import { getUsersList, updateRoles } from "../../services/user";
import Confirm from "../Confirm";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";

import "./UserList.css";

export default function UserList() {
  const token = getToken();
  const isCR = token.role === "CR";
  const initialState = {
    error: "",
    done: "",
    confirm: "",
    user: null,
    loading: true,
    prevPage: null,
    nextPage: null,
    users: [],
  };

  const [state, setState] = useState(initialState);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadUsers(), [setState]);

  const loadUsers = (page) => {
    setState({ ...state, loading: true });
    getUsersList(page).then((list) => {
      const prevPage = list.pagination.previous;
      const nextPage = list.pagination.next;
      setState({
        ...state,
        loading: false,
        users: list.items,
        prevPage: prevPage === list.pagination.first ? null : prevPage,
        nextPage,
      });
    });
  };

  const onPageChange = (page) => () => {
    loadUsers(page);
  };

  const updateUserRole = (user) => () => {
    const { roles, ID, loginID } = user;
    let message = "";
    let newRoles;
    if (roles.includes("CS")) {
      newRoles = roles.filter((role) => role !== "CS");
      message = `CS role revoked from ${loginID} user`;
    } else if (!roles.includes("CR")) {
      newRoles = [...roles, "CS"];
      message = `CS role granted to ${loginID} user`;
    }

    setState({ ...state, loading: true });

    updateRoles(ID, newRoles).then((result) => {
      if (result && result.errorMessage) {
        return setState({
          ...state,
          loading: false,
          error: result.errorMessage,
          user: null,
          confirm: "",
        });
      }

      user.roles = newRoles;

      return setState({
        ...state,
        loading: false,
        done: message,
        user: null,
        confirm: "",
      });
    });
  };

  const changeRole = (user) => () => {
    if (!isCR) {
      return null;
    }

    const { roles, loginID } = user;
    let confirm = "";

    if (roles.includes("CS")) {
      confirm = [
        "You about to revoke CS role from ",
        <b>{loginID}</b>,
        "user. If you proceed, ",
        <b>{loginID}</b>,
        "won`t be able to use Org Manager.",
      ];
    } else if (!roles.includes("CR")) {
      confirm = [
        "You about to grant CS role to ",
        <b>{loginID}</b>,
        " user. If you proceed, ",
        <b>{loginID}</b>,
        " will be able to use Org Manager to create and manage orgnizations.",
      ];
    }

    return setState({ ...state, user, confirm });
  };

  const renderUsers = (user, id) => {
    const className = id % 2 ? "row-even" : "row-odd";
    const fullName = `${user.firstName} ${user.lastName}`;

    let role = "-";
    let control = "Add Role";

    if (user.roles.includes("CR")) {
      role = "CR";
      control = null;
    } else if (user.roles.includes("CS")) {
      role = "CS";
      control = "Remove Role";
    }

    return (
      <div className={`user-rows ${className}`} key={user.ID}>
        <div className="user-table-row user-loginid">{user.loginID}</div>
        <div className="user-table-row user-name">{fullName}</div>
        <div className="user-table-row user-role">{role}</div>
        <div className="user-table-row user-control">
          {control ? (
            <button type="button" className="add-role" disabled={!isCR} onClick={changeRole(user)}>
              {control}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  const renderPages = () => {
    const { prevPage, nextPage } = state;

    return (
      <div className="pagination">
        <button type="button" disabled={!prevPage} onClick={onPageChange()}>
          first
        </button>
        <button type="button" disabled={!prevPage} onClick={onPageChange(prevPage)}>
          prev
        </button>
        <button type="button" disabled={!nextPage} onClick={onPageChange(nextPage)}>
          next
        </button>
      </div>
    );
  };

  const renderContent = () => {
    if (state.users) {
      return (
        <div className="users-list-table">
          <div className="users-head">
            <div className="user-table-row user-loginid">LoginID</div>
            <div className="user-table-row user-name">Name</div>
            <div className="user-table-row user-role">Role</div>
            <div className="user-table-row user-control">Control</div>
          </div>
          {state.users.map(renderUsers)}
          {renderPages()}
        </div>
      );
    }

    return <div className="users-list-table">Nothing to display</div>;
  };

  return (
    <div className="users-list">
      <h3>
        <a className="switch-list" href="/list">
          Orgs List
        </a>
        <span className="switch-list">CS Users List</span>
      </h3>
      <Loader loading={state.loading}>
        <Confirm
          message={state.confirm}
          onCancel={() => setState({ ...state, confirm: "", user: null })}
          onConfirm={updateUserRole(state.user)}
        />
        <Error error={state.error} onDismiss={() => setState({ ...state, error: false })} />
        <Success message={state.done} onDismiss={() => setState({ ...state, done: false })} />
        <div className="users-list-body">{renderContent()}</div>
      </Loader>
    </div>
  );
}
