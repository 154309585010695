import { fetchWithToken } from "./api";

export default class Toggles {
  static async getComplete(orgID) {
    const globalUrl = "/utils/v3.0/toggles";
    const orgUrl = `/utils/v3.0/toggles/evaluate/${orgID}?complete=true`;

    const globalToggles = await fetchWithToken(globalUrl);
    const orgData = await fetchWithToken(orgUrl);
    const orgToggles = {};
    orgData.forEach((orgToggle) => {
      const { name, enabled } = orgToggle;
      orgToggles[name] = { enabled };
    });

    return {
      org: orgToggles,
      global: globalToggles,
    };
  }

  static async patchToggle(orgId, toggleId, enabled) {
    const url = `/utils/v3.0/toggles/${toggleId}/${orgId}`;
    const options = {
      method: "PATCH",
      body: JSON.stringify({ enabled }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }
}
