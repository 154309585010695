import { fetchWithToken } from "./api";

export async function runPolicyForOrg(orgID, data) {
  const url = `/utils/v3.0/policyrun/${orgID}`;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options, null, true);
}
