import { StrictMode } from "react";
import ReactDOM from "react-dom";

import "./index.css";

import App from "./App";

const prodURL = "admin.app-center.com";
const stageURL = "stage-admin.app-center.com";
export const HOST = window.location.host;

const environment = () => {
  let env;
  // temp url till we move it over to admin.app-center.com
  if (HOST === prodURL || HOST === "mr-admin.app-center.com") {
    env = "prod";
  } else if (HOST === stageURL) {
    env = "stage";
  } else {
    env = "dev";
  }
  return env;
};

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.querySelector("#root"),
);
