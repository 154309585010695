import { useEffect, useState } from "react";
import { Box, Container, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

import Chart from "../Chart";
import ComdataStatus from "../Status/ComdataStatus";
import ElasticStatus from "../Status/ElasticStatus";
import PollerStatus from "../Status/PollerStatus";
import RepoterStatus from "../Status/RepoterStatus";

const APP_BAR_MOBILE = 0;
const APP_BAR_DESKTOP = 0;

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default () => {
  const [data, setData] = useState({
    series: [],
    labels: [],
    elastic: null,
    poller: null,
    comdata: null,
  });

  useEffect(() => {
    document.title = "Center Status";

    fetchData();
  }, []);

  const fetchData = async () => {
    // TODO Remove no-cors
    const response = await fetch("https://f10-dev-api.centercard.com/diagnostic-pack/v3.0/status");
    const statuses = await response.json();

    if (statuses?.length > 0) {
      const series = statuses.map((status) =>
        !!status.data.elastic && !!status.data.poller && !!status.data.comdata ? 1 : 0,
      );
      const labels = statuses.map((status) => new Date(status.createAt).getTime());

      series.push(series.at(-1));
      labels.push(Date.now());

      const lastStatus = statuses.at(-1);

      setData({
        series: [
          {
            name: "System metrics",
            type: "area",
            data: series,
          },
        ],
        labels,
        elastic: lastStatus.data.elastic,
        poller: lastStatus.data.poller,
        comdata: lastStatus.data.comdata,
      });
    }

    // setData({
    // 	series: [{
    // 		name: 'Elastic',
    // 		type: 'area',
    // 		// data: [1, 1]
    // 		data: statuses.map(status => status.data.elastic)
    // 	}],
    // 	// labels: [
    // 	// 	'08/12/2021',
    // 	// 	'08/13/2021'
    // 	// ]
    // 	labels: statuses.map(status => status.createAt)
    // });

    // if (statuses.length > 0) {
    // 	const elastic = statuses[statuses.length - 1].data.elastic;
    // 	const status = !!elastic ? 'Operational' : 'Something went wrong'
    // 	setElasticStatus(status);
    // }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 1:
        return "Operational";
      case 0:
        return "Something went wrong";
      default:
        return "Loading";
    }
  };

  const getOverallStatus = () => {
    if (data.elastic === null) return "Loading";
    if (!!data.elastic && !!data.poller && !!data.comdata) return "All Systems Operational";

    const servicesAreDown = [];

    if (!data.elastic) servicesAreDown.push("Elastic");

    if (!data.poller) servicesAreDown.push("Poller");

    if (!data.comdata) servicesAreDown.push("Comdata");

    return `Services are down: ${servicesAreDown.join(", ")}`;
  };

  return (
    <MainStyle>
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <ElasticStatus status={getStatusText(data.elastic)} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <PollerStatus status={getStatusText(data.poller)} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <ComdataStatus status={getStatusText(data.comdata)} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <RepoterStatus />
          </Grid>

          <Grid item xs={12}>
            <Chart series={data.series} labels={data.labels} description={getOverallStatus()} />
          </Grid>
        </Grid>
      </Container>
    </MainStyle>
  );
};
