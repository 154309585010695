import { fetchWithToken } from "./api";

export async function create(orgID, data) {
  const url = `/costcenters/v3.0?orgid=${orgID}`;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options);
}

export async function getCCs(orgId) {
  const url = `/costcenters/v3.0?limit=100&orgid=${orgId}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options);
}
