import { useState } from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";

export default function FeatureToggles(props) {
  const { show, orgID, toggles, patchToggle } = props;
  const initialState = {
    loading: false,
    error: false,
    done: false,
    orgID,
    orgToggles: toggles.org,
    globalToggles: toggles.global,
  };

  const [state, setState] = useState(initialState);

  const switchToggle = async (toggleId, enabled) => {
    setState({ ...state, loading: true });
    const { orgToggles } = state;

    const error = await patchToggle(orgID, toggleId, enabled);

    if (!error) {
      orgToggles[toggleId] = { enabled };
    }

    setState({ ...state, loading: false, error, orgToggles });
  };

  const { loading, error, done } = state;

  if (!show) {
    return "";
  }

  const renderToggles = () => {
    const { orgToggles, globalToggles } = state;
    const mergedToggles = [];
    const toggleIds = Object.keys(globalToggles);
    toggleIds.forEach((toggleId) => {
      const override = orgToggles[toggleId]
        ? orgToggles[toggleId].enabled
        : globalToggles[toggleId].enabled;

      mergedToggles.push({
        toggleId,
        default: globalToggles[toggleId].enabled ? "enabled" : "disabled",
        override: override ? "enabled" : "disabled",
        isDefault: globalToggles[toggleId].enabled === override,
      });
    });

    return (
      <div className="toggles-list">
        {mergedToggles.map((toggle, id) => {
          const className = id % 2 ? "row-even" : "row-odd";
          const { toggleId } = toggle;
          return (
            <div className={`toggle-row ${className}`} key={toggleId}>
              <div className="toggle-col toggle-id">{toggleId}</div>
              <div className="toggle-col toggle-default">
                <span className={`toggle-${toggle.default}`}>{toggle.default}</span>
              </div>
              <div className="toggle-col toggle-override">
                <span
                  className={`toggle-${toggle.override} ${
                    toggle.isDefault ? "toggle-default" : ""
                  }`}
                >
                  {toggle.override}
                </span>
              </div>
              <div className="toggle-col toggle-switch">
                <Switch
                  className="toggle-ovrride-switch"
                  disabled={loading}
                  checked={toggle.override === "enabled"}
                  onColor="#716c32"
                  height={25}
                  width={50}
                  onChange={(enabled) => switchToggle(toggleId, enabled)}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderBody = () => (
    <div className="toggles-body">
      <div className="toggles-head">
        <p>Feature Toggles</p>
        <div className="toggle-row">
          <div className="toggle-col toggle-id">Toggle Name (ID)</div>
          <div className="toggle-col toggle-default">Default (Global)</div>
          <div className="toggle-col toggle-override">Override (Org)</div>
        </div>
      </div>
      {renderToggles()}
    </div>
  );

  return (
    <Loader loading={false}>
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      {renderBody()}
    </Loader>
  );
}

FeatureToggles.propTypes = {
  show: PropTypes.bool,
  orgID: PropTypes.string.isRequired,
  toggles: PropTypes.object.isRequired,
  patchToggle: PropTypes.func.isRequired,
};
