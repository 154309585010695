import { useState } from "react";

import { cacheToken, getToken } from "./services/api";

export default function useToken() {
  const [token, setToken] = useState(getToken());

  const saveToken = (newToken) => {
    cacheToken(newToken);
    setToken(newToken);
  };

  return {
    setToken: saveToken,
    token,
  };
}
