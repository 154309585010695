import moment from "moment";

import apiUrl, { slot } from "./slotConfig";

const CORS_PROXY = "https://cors.centercard.com";
const API_URL = apiUrl || import.meta.env.REACT_APP_BUILD_API;

export const getUriWithCorsProxy = (uri) => `${CORS_PROXY}/${uri}`;

export const getToken = () => {
  const auth = sessionStorage.getItem("auth");
  return auth ? JSON.parse(auth) : null;
};

export const cacheToken = (token) => {
  token.expires_on = moment.utc().add(token.expires_in, "seconds").format();
  sessionStorage.setItem("auth", JSON.stringify(token));
};

const refreshToken = async () => {
  const token = getToken();
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token`), {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      grant_type: "refresh_token",
      refresh_token: token ? token.refresh_token : null,
    }),
  });

  if (response.status === 200) {
    const newToken = await response.json();
    const user = await getSelf(newToken);
    const access = checkAccess(user);

    newToken.expires_on = moment().valueOf() + newToken.expires_in * 1000;
    newToken.loginID = user.loginID;
    newToken.hasAccess = access.hasAccess;
    newToken.role = access.role;

    return newToken;
  }

  return null;
};

export const login = async (username, password) => {
  const auth = window.btoa(`${username}:${password}`);
  const response = await fetch(getUriWithCorsProxy(`${API_URL}/oauth/v3.0/token`), {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Basic ${auth}`,
      "Content-Type": "application/json",
    },
  });

  if (!response.ok) {
    if (response.status === 403) {
      throw new Error("Invalid email or password. Please check and try logging in again.");
    }
    throw new Error("Oops! There was a problem logging you in. Please try again.");
  }

  const token = await response.json();
  const user = await getSelf(token);
  const access = checkAccess(user);
  token.loginID = user.loginID;
  token.hasAccess = access.hasAccess;
  token.role = access.role;

  return token;
};

export const logout = async () => {
  sessionStorage.clear();
  if (slot) {
    window.sessionStorage.setItem("cloud-slot", slot);
  }
  window.location = "/";
};

export const fetchWithToken = async (url, options = {}, token, isNeedRes = false) => {
  let accessToken = token && token.access_token;
  if (!accessToken) {
    token = getToken();

    if (!token) {
      return logout();
    }

    accessToken = token.access_token;
  }

  const optionsWithToken = options || {};
  if (accessToken != null) {
    optionsWithToken.headers = optionsWithToken.headers || {};
    optionsWithToken.headers.Authorization = `Bearer ${accessToken}`;
  }

  const completeUrl = url.includes("http") ? url : API_URL + url;
  console.log("URL", getUriWithCorsProxy(completeUrl));
  const response = await fetch(getUriWithCorsProxy(completeUrl), optionsWithToken);

  if (response.status === 403 || response.status === 401) {
    const expiresOn = moment(token.expires_on).valueOf();
    const isExpired = expiresOn <= moment().valueOf();

    if (isExpired) {
      const newToken = await refreshToken();

      if (newToken) {
        cacheToken(newToken);
        return fetchWithToken(url, options, newToken);
      }
    }

    return logout();
  }

  if (isNeedRes) return response;

  if (response.status === 404) {
    return null;
  }

  if (response.status === 204) {
    return null;
  }

  return response.json();
};

async function getSelf(token) {
  const url = "/users/v3.0/me";

  const user = await fetchWithToken(url, {}, token);

  if (!user) {
    await logout();
  }

  return user;
}

function checkAccess(user) {
  const role = user && user.roles && user.roles.find((r) => r === "CR" || r === "CS");
  const hasAccess = !!role;
  return { role, hasAccess };
}
