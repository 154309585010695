import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import { create } from "../../services/costcenters";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";
import customStyles from "./selectorStyle";

export default function CostCenter(props) {
  const { show, orgID, ccs, addCC, orgName } = props;
  const initialState = {
    loading: false,
    error: false,
    done: false,
    name: orgName,
    parentID: "",
    externalID: "",
  };

  const [state, setState] = useState(initialState);

  const onSubmit = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const { name, parentID, externalID } = state;
    const result = await create(orgID, { name, parentID, externalID });

    if (result && result.ID) {
      addCC({ name, ID: result.ID });
      setState({
        ...initialState,
        done: `Cost Center "${name}" added`,
      });
      return;
    }

    const errorMessage = result && result.errorMessage;
    setState({ ...state, error: errorMessage, loading: false });
  };

  if (!show) {
    return "";
  }

  const { loading, error, done } = state;

  return (
    <Loader loading={loading}>
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      <div className="cost-center-body">
        <div className="cost-center-count">Cost Centers in org: {ccs.length}</div>
        <div className="cost-center">
          <form onSubmit={onSubmit}>
            <div className="data-input">
              <label htmlFor="new-cost-center-name">
                Name *
                <input
                  id="new-cost-center-name"
                  type="text"
                  required
                  value={state.name}
                  onChange={(e) => setState({ ...state, name: e.target.value })}
                />
              </label>
            </div>
            <div className="data-input">
              <label htmlFor="new-cost-center-ext-id">
                External ID
                <input
                  id="new-cost-center-ext-id"
                  type="text"
                  value={state.externalID}
                  onChange={(e) => setState({ ...state, externalID: e.target.value })}
                />
              </label>
            </div>
            <div className="data-input parent-cc">
              <label>
                Parent {ccs.lenght ? "*" : ""}
                <Select
                  className="cc-selector"
                  required={!!ccs.lenght}
                  isSearchable={false}
                  options={ccs.map((cc) => ({ value: cc.ID, label: cc.name }))}
                  onChange={(val) => setState({ ...state, parentID: val.value })}
                  styles={customStyles()}
                />
              </label>
            </div>
            <div className="data-input submit">
              <button type="submit">Add Cost Center</button>
            </div>
          </form>
        </div>
      </div>
    </Loader>
  );
}

CostCenter.propTypes = {
  show: PropTypes.bool,
  orgID: PropTypes.string,
  ccs: PropTypes.array,
  addCC: PropTypes.func.isRequired,
  orgName: PropTypes.string.isRequired,
};
