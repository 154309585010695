import { Box, Card, CardHeader } from "@material-ui/core";
import { isNil, merge } from "lodash";
import ReactApexChart from "react-apexcharts";

import BaseOptionChart from "./BaseOptionChart";

export default ({ series, labels, description }) => {
  const chartOptions = merge(BaseOptionChart(), {
    stroke: {
      // width: [0, 2, 3]
      width: [2],
    },
    plotOptions: { bar: { columnWidth: "11%", borderRadius: 4 } },
    fill: {
      type: ["solid", "gradient", "solid"],
      // type: ['gradient']
    },
    labels,
    yaxis: {
      show: false,
    },
    xaxis: { type: "datetime" },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (!isNil(y)) {
            return `${y * 100}%`;
            // return (y !== 0) ? 'Operational' : "Down"
          }
          return y;
        },
      },
    },
  });

  return (
    <Card>
      <CardHeader title="System Metrics" subheader={description} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={series} options={chartOptions} height={156} />
      </Box>
    </Card>
  );
};
