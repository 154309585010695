import { fetchWithToken } from "./api";
import { getUsersCount } from "./user";

export default class Orgs {
  static async getList(name, status, offset = null, limit = 10, accountCode) {
    if (limit < 1) limit = 1;
    if (limit > 25) limit = 25;

    const query = { limit };

    if (offset) {
      query.offset = offset;
    }

    if (name) query.prefix = name;
    if (status) query.status = status;
    if (accountCode) query.accountcode = accountCode;

    const qString = new URLSearchParams(query).toString();
    const url = `/organizations/v3.0?${qString}`;

    const data = await fetchWithToken(url);

    if (!data) return { items: [] };

    return {
      items: data.items,
      nextOffset: data.pagination?.offset || null,
      previousOffset: data.pagination?.previousOffset || null,
    };
  }

  static async activate(orgId) {
    const url = `/organizations/v3.0/${orgId}/status`;
    const options = {
      method: "PATCH",
      body: JSON.stringify({ status: "active" }),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async addCardProgram(orgId, comdata, defaultLimitProfile) {
    const { accountCode, customerID } = comdata;
    const cardProgram = {
      programID: "Comdata Credit",
      comdataID: {
        accountCode,
        customerID,
      },
      defaultLimitProfile,
    };

    const url = `/organizations/v3.0/${orgId}/cardprograms`;
    const options = {
      method: "POST",
      body: JSON.stringify(cardProgram),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async updateCardProgram(orgId, cardProgramId, data) {
    const url = `/organizations/v3.0/${orgId}/cardprograms/${cardProgramId}`;
    const options = {
      method: "PATCH",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async deleteCardProgram(orgId, cardProgramId) {
    const url = `/organizations/v3.0/${orgId}/cardprograms/${cardProgramId}`;
    const options = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async create(data) {
    const url = "/organizations/v3.0";
    data.status = "onboarding";
    data.verified = true;
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async update(data) {
    const url = `/organizations/v3.0/${data.ID}`;

    const options = {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };

    const result = await fetchWithToken(url, options);
    if (result && result.errorMessage) {
      return result;
    }

    return { ID: data.ID };
  }

  static async submit(data) {
    if (data.ID) {
      return Orgs.update(data);
    }

    return Orgs.create(data);
  }

  static async getCostCenters(orgID) {
    const url = `/costcenters/v3.0?orgid=${orgID}`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    return fetchWithToken(url, options);
  }

  static async getByID(orgID) {
    const url = `/organizations/v3.0/${orgID}?tmc=spotnana`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    const promises = [
      fetchWithToken(url, options),
      getUsersCount(orgID),
      this.getCostCenters(orgID),
    ];
    const [org, users, ccs] = await Promise.all(promises);

    return { org, users, ccs };
  }
}
