import { fetchWithToken } from "./api";

export async function addCommPrefs(orgID, data) {
  const url = `/communicationpreferences/v3.0?orgid=${orgID}`;
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options);
}
