import "./Confirm.css";

export default function Confirm({ message, onCancel, onConfirm }) {
  if (message) {
    return (
      <div className="confirm-dialog-outer">
        <div className="confirm-dialog-inner">
          <div className="confirm-message">{message}</div>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <button type="button" className="cancel" onClick={onCancel} autoFocus>
            Cancel
          </button>
          <button type="button" className="confirm" onClick={onConfirm}>
            Confirm
          </button>
        </div>
      </div>
    );
  }

  return "";
}
