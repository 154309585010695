import { fetchWithToken } from "./api";

export async function create(data) {
  const url = "/users/v3.0?nowelcomeemail=true";
  const options = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options);
}

export async function updateRoles(id, roles) {
  const url = `/users/v3.0/${id}`;
  const options = {
    method: "PUT",
    body: JSON.stringify({ roles }),
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetchWithToken(url, options);
}

export async function getUsersCount(orgId) {
  const url = `/users/v3.0?limit=1&orgid=${orgId}`;
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = await fetchWithToken(url, options);

  if (!data) {
    return 0;
  }

  return data.pagination.totalItems;
}

export async function getUsersList(page) {
  const url = page || "/users/v3.0";
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };

  const data = await fetchWithToken(url, options);

  if (!data) {
    return 0;
  }

  return data;
}
