const style = (width = 254) => ({
  control: (base) => ({
    ...base,
    padding: 0,
    minHeight: 28,
    height: 28,
    minWidth: width,
    width,
    border: "none",
  }),
  singleValue: (base) => ({
    ...base,
    top: 0,
    transform: "none",
    margin: 0,
    height: 30,
    lineHeight: "28px",
  }),
  multiValue: (base) => ({
    ...base,
    margin: "-4px 6px 6px 0",
    height: 30,
    lineHeight: "24px",
  }),
  placeholder: () => ({
    display: "none",
  }),
  valueContainer: (base) => ({
    ...base,
    minHeight: 28,
    height: 28,
  }),
  indicatorsContainer: (base) => ({
    ...base,
    padding: 0,
    minHeight: 28,
    height: 28,
    overflow: "hidden",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: 0,
    minHeight: 29,
    height: 29,
  }),
  input: (base) => ({
    ...base,
    minHeight: 30,
    height: 30,
    top: 0,
    margin: 0,
    padding: 0,
  }),
});

export default style;
