import { useState } from "react";
import loading from "@iconify/icons-ant-design/loading-3-quarters-outline";
import { Icon } from "@iconify/react";
import { isNull } from "lodash";
import PropTypes from "prop-types";

import Orgs from "../../services/org";

export const CardProgramType = ({ isPrefunded, programID, orgID, overwrittenAccFunding }) => {
  const [isDirty, markAsDirty] = useState(false);
  const [programType, setProgramType] = useState(isPrefunded);
  const [overwrittenAccFundingState, setOverwriteAccFundingState] = useState(overwrittenAccFunding);
  const [loader, setLoader] = useState(false);
  const onSave = async (e) => {
    e.preventDefault();
    try {
      setLoader(true);
      await Orgs.updateCardProgram(orgID, programID, {
        isPrefunded: programType,
        overwrittenAccFunding: overwrittenAccFundingState,
      });
    } catch (err) {
      console.log("failed to update Card Program", err);
    } finally {
      setLoader(false);
      markAsDirty(false);
    }
  };

  const onChangeProgramType = (value) => {
    setProgramType(value);
    if (isPrefunded === value && overwrittenAccFunding === overwrittenAccFundingState) {
      markAsDirty(false);
    } else {
      markAsDirty(true);
    }
  };

  const onChangeOverwrite = (value) => {
    setOverwriteAccFundingState(value);
    if ((!value && value === overwrittenAccFunding) || (value && isPrefunded === programType)) {
      markAsDirty(false);
    } else {
      markAsDirty(true);
    }
  };

  return (
    <form onSubmit={onSave} className="program-type-settings">
      <div className="radio-group flex">
        <input
          type="radio"
          id="overwrittenAccFundingFalse"
          name="overwrittenAccFunding"
          checked={overwrittenAccFundingState === false}
          onChange={() => onChangeOverwrite(false)}
        />
        <div>
          <label htmlFor="overwrittenAccFundingFalse">Use Comdata API for notifications</label>
          <p className="subtext">
            This value is set by the daily Comdata API response. To overwrite this, please update
            accordingly.
          </p>
        </div>
      </div>
      <div className="radio-group flex">
        <input
          type="radio"
          id="overwrittenAccFundingTrue"
          name="overwrittenAccFunding"
          checked={overwrittenAccFundingState === true}
          onChange={() => onChangeOverwrite(true)}
        />
        <div>
          <label htmlFor="overwrittenAccFundingTrue">
            Overwrite account funding for notifications
          </label>
          <p className="subtext">
            Selecting this option will manually set the organization's card program and will ignore
            the daily Comdata API response
          </p>
        </div>
      </div>
      <div className="radio-group secondary-group">
        <label htmlFor="credit">
          <input
            type="radio"
            id="credit"
            name="programType"
            disabled={!overwrittenAccFundingState}
            checked={programType === false}
            onChange={() => onChangeProgramType(false)}
          />
          Credit
        </label>
        <label htmlFor="prefunded">
          <input
            type="radio"
            id="prefunded"
            name="programType"
            disabled={!overwrittenAccFundingState}
            checked={programType === true}
            onChange={() => onChangeProgramType(true)}
          />
          Prefunded
        </label>
        <label htmlFor="needsReview">
          <input
            type="radio"
            id="needsReview"
            name="programType"
            disabled={!overwrittenAccFundingState}
            checked={isNull(programType)}
            onChange={() => onChangeProgramType(null)}
          />
          Needs Review
        </label>
      </div>
      <button type="submit" disabled={!isDirty}>
        {loader ? (
          <Icon
            style={{
              animation: "spin 2s linear infinite",
              verticalAlign: "text-bottom",
            }}
            icon={loading}
          />
        ) : (
          "Save"
        )}
      </button>
    </form>
  );
};

CardProgramType.defaultProps = {
  isPrefunded: null,
  overwrittenAccFunding: false,
};

CardProgramType.propTypes = {
  isPrefunded: PropTypes.oneOf([true, false, null]),
  programID: PropTypes.string,
  orgID: PropTypes.string,
  overwrittenAccFunding: PropTypes.bool,
};
