export const Input = ({ label, hidden, required, value, className, onChange, id, ...rest }) => (
  <div className="data-input">
    <label htmlFor={id} hidden={hidden}>
      {label}
      <input
        id={id}
        type="text"
        required={required}
        value={value}
        className={className}
        hidden={hidden}
        onChange={onChange}
        {...rest}
      />
    </label>
  </div>
);
