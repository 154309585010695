import { BrowserRouter, Redirect, Route, Switch as RouteSwitch } from "react-router-dom";
import Switch from "react-switch";

import Login from "./components/Login";
import Monitoring from "./components/Monitoring";
import OrgEdit from "./components/OrgEdit";
import OrgList from "./components/OrgList";
import UserList from "./components/UserList";
import { logout } from "./services/api";
import { slot } from "./services/slotConfig";
import ThemeConfig from "./theme";
import useToken from "./useToken";

import "./App.css";

const App = () => {
  const theme = localStorage.getItem("useDarkTheme") === "true";
  const { token, setToken } = useToken();

  const toggleTheme = (checked) => {
    localStorage.setItem("useDarkTheme", checked);
    window.location.reload();
  };

  const renderUserSection = () => {
    if (!token) {
      return "";
    }

    return (
      <div className="user-section">
        <div className="user-login-id">{token.loginID}</div>
        <span>Dark theme: </span>
        <Switch
          className="theme-switch"
          checked={theme}
          onColor="#716c32"
          height={30}
          width={60}
          onChange={toggleTheme}
        />
        <button type="button" onClick={logout}>
          Logout
        </button>
      </div>
    );
  };

  const renderBody = () => {
    // any user without token can access status page

    if (!token) {
      return (
        <div className="wrapper">
          <Login setToken={setToken} />
        </div>
      );
    }

    if (!token.hasAccess) {
      return (
        <div className="wrapper">
          <p>Only CR/CS users are allowed here.</p>
          <button type="button" className="long-button" onClick={logout}>
            Try another account
          </button>
        </div>
      );
    }

    return (
      <div className="content">
        <div className="wrapper">
          <BrowserRouter>
            <RouteSwitch>
              <Route path="/login">
                <Login setToken={setToken} />
              </Route>
              <Route path="/list">
                <OrgList />
              </Route>
              <Route path="/create">
                <OrgEdit />
              </Route>
              <Route path="/edit/:orgID">
                <OrgEdit />
              </Route>
              <Route path="/users">
                <UserList />
              </Route>
              <Redirect exact from="/" to="/list" />
            </RouteSwitch>
          </BrowserRouter>
        </div>
      </div>
    );
  };

  if (window.location.pathname === "/status" || window.location.pathname === "/status/") {
    return (
      <ThemeConfig>
        <Monitoring />
      </ThemeConfig>
    );
  }

  return (
    <div className="App">
      <div className="app-header">
        {slot && <div className="cloud-slot">{slot.slice(0, 3)}</div>}
        <div className="placeholder" />
        <div className="title-section">
          <div className="sides left-side">
            <div className="top-duck left-duck" />
          </div>
          <div className="title">
            <h1>Org Manager</h1>
          </div>
          <div className="sides right-side">
            <div className="top-duck right-duck" />
          </div>
        </div>
        {renderUserSection()}
      </div>
      {renderBody()}
    </div>
  );
};

export default App;
