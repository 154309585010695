import { Input } from "../../Input/Input";

export const ExistEntityExistSpotnana = ({ partner, changePartnerData, idx }) => (
  <>
    <Input
      id="new-tmc-partner-orgName"
      label="External Org Name *"
      className="disabled"
      disabled
      value={partner.orgName}
      onChange={(e) => changePartnerData(idx, "orgName", e.target.value)}
      required
    />
    <Input
      id="new-tmc-partner-orgID"
      label="External Org ID"
      value={partner.orgID}
      onChange={(e) => changePartnerData(idx, "orgID", e.target.value)}
    />
    <Input
      id="new-tmc-partner-legalEntityID"
      label="Legal Entity ID"
      value={partner.legalEntityID}
      onChange={(e) => changePartnerData(idx, "legalEntityID", e.target.value)}
    />
    <Input
      label="Email Domains"
      id="new-tmc-partner-emailDomains"
      className="disabled"
      disabled
      placeholder="example.com"
      value={partner.emailDomains?.join()}
      onChange={(e) => changePartnerData(idx, "emailDomains", e.target.value.split(","))}
    />
  </>
);
