import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import Orgs from "../../services/org";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";
import customStyles from "./selectorStyle";
import { ExistEntityExistSpotnana } from "./Spotnana/ExistEntityExistSpotnana";
import { ExistEntityNewSpotnana } from "./Spotnana/ExistEntityNewSpotnana";
import { NewEntityNewSpotnana } from "./Spotnana/NewEntityNewSpotnana";
import TravelContracts from "./TravelContracts";

export const EXIST_ORG_NEW_TMC = 1;
export const NEW_ORG_NEW_TMC = 2;
export const EXIST_ORG_EXIST_TMC = 3;

const reduceOption = (currOptions = [], chosenPartners = []) => {
  const chosenTMCs = new Set(chosenPartners.map((e) => e.name));
  return currOptions.filter((option) => !chosenTMCs.has(option.name));
};

const mappingPartnerFields = (fields) => {
  const organization = fields?.spotnana?.organizationNode?.organization;

  if (!organization) {
    return fields;
  }

  const emergencyContacts = organization.emergencyContactInfos?.map((contact) => ({
    firstName: contact.name.given,
    middleName: contact.name.middle,
    lastName: contact.name.family1,
    designation: contact.designation,
    email: contact.email,
    phone: `${contact.phoneNumber.countryCode}${contact.phoneNumber.rawInput}`,
  }));

  return {
    ...fields,
    spotnana: {
      ...fields.spotnana,
      emergencyContacts,
      emailDomains: organization.emailDomains,
      orgName: organization.name,
    },
  };
};

const tmcOptions = [{ name: "spotnana", label: "Spotnana" }];

const linkedOption = [
  {
    value: EXIST_ORG_NEW_TMC,
    label: "Create a new legal entity within an existing org",
  },
  { value: NEW_ORG_NEW_TMC, label: "Create a new Spotnana org & legal entity" },
  {
    value: EXIST_ORG_EXIST_TMC,
    label: "Migration to Link to an existing Spotnana org & legal entity",
  },
];

const fieldsMap = {
  [NEW_ORG_NEW_TMC]: NewEntityNewSpotnana,
  [EXIST_ORG_NEW_TMC]: ExistEntityNewSpotnana,
  [EXIST_ORG_EXIST_TMC]: ExistEntityExistSpotnana,
};

export default function TravelManagementCompanies(props) {
  const { show, orgData, loadOrg, formRef, onAddPartnerField } = props;
  const { ID, partnerFields } = orgData;

  const defObjEmergencyContacts = {
    firstName: "",
    middleName: "",
    lastName: "",
    designation: "",
    email: "",
    phone: "",
  };

  const [state, setState] = useState({});
  const [travelOrgValue, setTravelOrgValue] = useState({});

  useEffect(() => {
    const partnerFieldsArr =
      typeof partnerFields === "object" ? Object.values(mappingPartnerFields(partnerFields)) : [];

    const initialState = {
      loading: false,
      error: false,
      partnerFieldsArray: partnerFieldsArr || [],
      options: reduceOption(tmcOptions, partnerFieldsArr),
    };
    setState(initialState);
  }, [partnerFields]);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!formRef.current.checkValidity()) {
      formRef.current.at(-1).click();
      return;
    }

    setState({ ...state, loading: true });
    const { partnerFieldsArray } = state;
    const data = { ID, partnerFields: {}, shippingAddress: props.address };

    partnerFieldsArray.forEach((partnerField, idx) => {
      data.partnerFields[partnerField.name] = {
        name: partnerField.name,
        orgID: partnerField.orgID,
      };
      if (partnerField.name === "dwolla") {
        data.partnerFields.dwolla = partnerField;
      }
      if (partnerField.name === "spotnana") {
        data.partnerFields[partnerField.name].legalEntityID = partnerField.legalEntityID;
      }
      if (partnerField.name === "spotnana") {
        data.partnerFields[partnerField.name].spotnanaData = {
          emailDomains: partnerField.emailDomains,
          emergencyContacts: partnerField.emergencyContacts,
          orgName: partnerField.orgName,
          linkedToExistingOrg: travelOrgValue[idx] !== NEW_ORG_NEW_TMC,
          name: partnerField.name,
          orgID: partnerField.orgID,
          action: partnerField.orgID && partnerField.legalEntityID ? "update" : "create",
        };
        if (partnerField.orgID && partnerField.legalEntityID) {
          data.partnerFields[partnerField.name].spotnanaData.legalEntityID =
            partnerField.legalEntityID;
        }
      }
    });

    const result = await Orgs.submit(data);
    const errorMessage = result && result.errorMessage;

    if (!errorMessage) {
      await new Promise((resolve) => setTimeout(resolve, 7000));
      loadOrg();
    }

    setState({
      ...state,
      done: errorMessage ? "" : `Organization "${props.orgData.name}" updated`,
      partnerFieldsArray: state.partnerFieldsArray,
      error: errorMessage,
      loading: false,
    });
  };

  if (!show) {
    return "";
  }

  const { loading, error, done } = state;
  const options = reduceOption(state.options, state.partnerFieldsArray);

  const changePartnerData = (parentID, field, value) => {
    setState((prevState) => {
      const newPartners = [...prevState.partnerFieldsArray];
      newPartners[parentID][field] = value;
      return {
        ...state,
        partnerFieldsArray: newPartners,
      };
    });
  };

  const handleChangeTravel = (idx) => (event) => {
    setTravelOrgValue({
      ...travelOrgValue,
      [idx]: event.value,
    });
  };

  const changeContractData = (parentID, id, field, value) => {
    setState((prevState) => {
      const newPartners = [...prevState.partnerFieldsArray];
      newPartners[parentID].emergencyContacts[id][field] = value;
      return {
        ...state,
        partnerFieldsArray: newPartners,
      };
    });
  };

  const addContact = (parentID) => {
    setState((prevState) => {
      const newPartners = [...prevState.partnerFieldsArray];
      const newObject = { ...newPartners[parentID] };
      newObject.emergencyContacts = newObject?.emergencyContacts?.length
        ? [...newObject.emergencyContacts, defObjEmergencyContacts]
        : [defObjEmergencyContacts];
      newPartners[parentID] = newObject;
      return {
        ...state,
        partnerFieldsArray: newPartners,
      };
    });
  };

  const deleteContract = (parentID, deleteID) => {
    setState((prevState) => {
      const newPartners = [...prevState.partnerFieldsArray];
      const newObject = { ...newPartners[parentID] };
      newObject.emergencyContacts = newPartners[parentID].emergencyContacts.filter(
        (_item, i) => i !== deleteID,
      );
      newPartners[parentID] = newObject;
      return {
        ...state,
        partnerFieldsArray: newPartners,
      };
    });
  };

  const getLinkedValue = (idx) => linkedOption.find((item) => item.value === travelOrgValue[idx]);

  const getFields = (idx, partner) => {
    const FieldsWrapper = fieldsMap[travelOrgValue?.[idx]];
    if (!FieldsWrapper) {
      return null;
    }
    return <FieldsWrapper idx={idx} partner={partner} changePartnerData={changePartnerData} />;
  };

  const getLinkedOptions = (partner, idx) => {
    if (partner.orgID && !travelOrgValue[idx]) {
      // preset select value if integration with tmc already exist
      const filtered = linkedOption.find((option) => option.value === EXIST_ORG_EXIST_TMC);
      if (!travelOrgValue[idx]) {
        setTravelOrgValue({
          ...travelOrgValue,
          [idx]: filtered?.value,
        });
      }
    }
    return linkedOption;
  };

  const renderPartnerSpotnanaBlock = (partner, idx) => (
    <>
      <div className="partner-block">
        <div className="data-input">
          <label htmlFor="linked-selector">
            Travel Org
            <Select
              id="linked-selector"
              value={getLinkedValue(idx)}
              className="tmc-selector"
              required={options?.length}
              isSearchable={false}
              options={getLinkedOptions(partner, idx)}
              onChange={handleChangeTravel(idx)}
              styles={customStyles()}
            />
          </label>
        </div>
        {getFields(idx, partner)}
      </div>
      <div>
        <div className="data-input partner-full-width">
          <label htmlFor="tmc-contract">
            Emergency Contacts:
            <button
              id="tmc-contract"
              type="button"
              className="contract-button"
              disabled={
                partner?.emergencyContacts?.length >= 15 || travelOrgValue[idx] !== NEW_ORG_NEW_TMC
              }
              onClick={() => addContact(idx)}
            >
              Add contact
            </button>
          </label>
        </div>
        <TravelContracts
          partner={partner}
          partnerID={idx}
          travelOrgValue={travelOrgValue}
          changeContractData={changeContractData}
          deleteContract={deleteContract}
        />
      </div>
    </>
  );

  const renderPartnerDwollaBlock = (partner, idx) => (
    <>
      <div className="partner-full-width">
        <label htmlFor="add-card-program-code">
          Dwolla Customer ID
          <input
            label="Dwolla Customer ID"
            type="text"
            readOnly
            value={partner.customerID}
            onClick={() => {
              navigator.clipboard.writeText(partner.customerID);
            }}
          />
        </label>
      </div>
      <div className="partner-full-width">
        <label htmlFor="dwolla-daily-schedule">
          <input
            id="dwolla-daily-schedule"
            type="checkbox"
            checked={!(partner.isDailyScheduleAllowed === false)}
            onChange={(e) => changePartnerData(idx, "isDailyScheduleAllowed", e.target.checked)}
          />
          Daily Payment Schedule Allowed
        </label>
      </div>
    </>
  );
  return (
    <Loader loading={loading}>
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      <div className="tmc" style={{ display: "flex", justifyContent: "center" }}>
        <form onSubmit={onSubmit}>
          {state?.partnerFieldsArray?.length
            ? state.partnerFieldsArray.map((partner, idx) => (
                <div
                  key={`${partner.name}-row-${idx}`}
                  style={{ textAlign: "left", margin: "12px 0px 12px 36px" }}
                >
                  <div>
                    <div className="data-input">
                      <button
                        disabled={partner.isLocked || partner.name === "dwolla"}
                        className="btn-remove-tmc"
                        type="button"
                        onClick={() => {
                          onAddPartnerField("");
                          setState((prevState) => {
                            const prevChosenPartners = [...prevState.partnerFieldsArray];
                            const newChosenPartners = [].concat(
                              prevChosenPartners.slice(0, idx),
                              prevChosenPartners.slice(idx + 1, prevChosenPartners.length),
                            );
                            return {
                              ...state,
                              partnerFieldsArray: newChosenPartners,
                              options: reduceOption(tmcOptions, newChosenPartners),
                            };
                          });
                        }}
                      >
                        X
                      </button>
                    </div>
                    <div className="data-input ">
                      <label htmlFor="tmc-selector">
                        Partner {state.options?.length ? "*" : ""}
                        <Select
                          value={{ label: partner.label, value: partner.name }}
                          className="tmc-selector"
                          required={options?.length}
                          isSearchable={false}
                          options={state.options.map((opt) => ({
                            value: opt.name,
                            label: opt.label,
                          }))}
                          onChange={(e) => {
                            onAddPartnerField(e.value);
                            setState((prevState) => {
                              const newPartners = [...prevState.partnerFieldsArray];
                              newPartners.at(-1).name = e.value;
                              newPartners.at(-1).label = e.label;
                              return {
                                ...state,
                                partnerFieldsArray: newPartners,
                                options: reduceOption(tmcOptions, newPartners),
                              };
                            });
                          }}
                          isDisabled={partner.isLocked || partner.name === "dwolla"}
                          styles={customStyles()}
                        />
                      </label>
                    </div>
                  </div>
                  {partner.name === "spotnana" && renderPartnerSpotnanaBlock(partner, idx)}
                  {partner.name === "dwolla" && renderPartnerDwollaBlock(partner, idx)}
                </div>
              ))
            : null}
          <div>
            <button
              type="button"
              onClick={() => {
                const withBlankPartner = [...state.partnerFieldsArray];
                withBlankPartner.push({ name: "", orgID: "" });
                setState({ ...state, partnerFieldsArray: withBlankPartner });
              }}
              disabled={
                state?.partnerFieldsArray?.filter((p) => tmcOptions.find((o) => p.name === o.name))
                  .length >= tmcOptions.length
              }
            >
              Add
            </button>
            <div className="submit">
              <button type="submit">Save</button>
            </div>
          </div>
        </form>
      </div>
    </Loader>
  );
}

TravelManagementCompanies.propTypes = {
  show: PropTypes.bool,
  orgData: PropTypes.object,
  formRef: PropTypes.object,
  loadOrg: PropTypes.func,
  onAddPartnerField: PropTypes.func,
};
