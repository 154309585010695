const maxSlots = 24;

const urlsBySlotName = () => {
  const data = {
    "stable-dev": "https://dev2-api.centercard.com",
    "stable-stage": "https://stage2-api.centercard.com",
  };

  for (let i = 1; i <= maxSlots; i++) {
    data[`f${i}-dev`] = `https://f${i}-dev-api.centercard.com`;
    data[`f${i}-stage`] = `https://f${i}-stage-api.centercard.com`;
  }

  return data;
};

const envBySlotUrl = () => {
  const data = {
    "https://dev2-api.centercard.com": "dev",
    "https://stage2-api.centercard.com": "stage",
    "https://api.centercard.com": "prod",
  };

  for (let i = 1; i <= maxSlots; i++) {
    data[`https://f${i}-dev-api.centercard.com`] = "dev";
    data[`https://f${i}-stage-api.centercard.com`] = "stage";
  }

  return data;
};

const slotEnvConfig = urlsBySlotName();

const apiUrlToEnv = envBySlotUrl();

const env = apiUrlToEnv[import.meta.env.REACT_APP_BUILD_API];
const cloudSlot = window.location.pathname.split("/")[1];
console.log(cloudSlot, window.location.pathname);

if (!cloudSlot) {
  window.sessionStorage.removeItem("cloud-slot");
}

const cloudSlotConfig = slotEnvConfig[`${cloudSlot}-${env}`];
if (cloudSlotConfig) {
  let url = window.location.origin;
  const cloudSlotPath = `/${cloudSlot}`;
  url += window.location.href.replace(url, "").replace(cloudSlotPath, "");
  window.history.replaceState(null, document.title, url);
  window.sessionStorage.setItem("cloud-slot", cloudSlot);
}

export const slot = window.sessionStorage.getItem("cloud-slot");
const apiUrl = slotEnvConfig[`${slot}-${env}`];
console.log(apiUrl, slot);
export default apiUrl;
