import { useState } from "react";
import PropTypes from "prop-types";

import meta from "../../../package.json";
import { login } from "../../services/api";
import Error from "../Error";
import Loader from "../Loader";

export default function Login({ setToken }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = await login(username, password).catch((err) => setError(err.message || err));
    setLoading(false);

    if (token) setToken(token);
  };

  return (
    <div className="login-page-body">
      <h3>Authorization required</h3>
      <Loader loading={loading}>
        <Error error={error} onDismiss={() => setError("")} />
        <div className="login-page-form">
          <form onSubmit={onLogin}>
            <p>Only for CR users.</p>
            <label htmlFor="login-username">
              <p>Username</p>
              <input
                id="login-username"
                type="text"
                onChange={(e) => setUsername(e.target.value)}
              />
            </label>
            <label htmlFor="login-password">
              <p>Password</p>
              <input
                id="login-password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </label>
            <div>
              <button type="submit" disabled={loading}>
                Login
              </button>
            </div>
          </form>
          <div className="app-version"> (Version {meta.version})</div>
        </div>
      </Loader>
    </div>
  );
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
