import { useEffect, useRef, useState } from "react";
import exclamationCircleOutlined from "@iconify/icons-ant-design/exclamation-circle-outlined";
import { Icon } from "@iconify/react";
import { find, isNull } from "lodash";
import { useParams } from "react-router-dom";
import Select from "react-select";

import Colors from "@core/ui-legacy/themes/colors";

import { addCommPrefs } from "../../services/communicationpreferences";
import Orgs from "../../services/org";
import Toggles from "../../services/toggles";
import { create } from "../../services/user";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";
import CardProgram from "./CardProgram";
import CostCenter from "./CostCenter";
import FeatureToggles from "./FeatureToggles";
import TravelManagementCompanies from "./TravelManagementCompanies";
import User from "./User";

import "./OrgEdit.css";

import PolicyRunOptions from "./PolicyRunOptions";
import customStyles from "./selectorStyle";

const currencyCodes = [
  { value: "USD", label: "USD (US dollar)" },
  { value: "CAD", label: "CAD (Canadian dollar)" },
];

export default function OrgEdit() {
  const { orgID } = useParams();
  const formRef = useRef(null);
  const [state, setState] = useState({
    loading: true,
    complete: false,
    showCP: false,
    showCC: false,
    showUser: false,
    showToggles: false,
    showTMCs: false,
    showPhoneField: false,
    showPolicyRunOptions: false,
    error: false,
    done: false,
    orgData: {
      ID: null,
      status: "",
      name: "",
      timeZone: "America/Chicago",
      currencyCode: "",
      fiscalYearStartDate: "",
      partnerFields: {},
    },
    address: {},
    cardPrograms: [],
    users: 0,
    ccs: [],
    toggles: [],
  });
  const mergeState = (prop, key, value) => {
    const part = { ...state[prop], [key]: value };
    setState({ ...state, [prop]: part });
  };

  const processInput = (prop, key) => (e) => {
    mergeState(prop, key, e.target.value);
  };

  const addCC = (cc) => {
    setState({ ...state, ccs: [...state.ccs, cc] });
  };

  const addUser = async (data) => {
    const result = await create(data);

    if (result && result.ID) {
      if (state.cardPrograms > 0 && state.orgData.status !== "active") {
        await Orgs.activate(state.orgData.ID);
      }

      setState({ ...state, users: 1 });
    }

    return result;
  };

  const addCardProgram = async (orgId, comdata, defaultLimitProfile) => {
    const result = await Orgs.addCardProgram(orgId, comdata, defaultLimitProfile);

    if (result && result.ID) {
      if (state.users > 0 && state.orgData.status !== "active") {
        await Orgs.activate(orgId);
      }

      const cardPrograms = state.cardPrograms || [];

      if (!cardPrograms.find((program) => program.ID === result.ID)) {
        cardPrograms.push({ comdataID: comdata, ID: result.ID });
      }

      setState({ ...state, cardPrograms });
    }

    return result;
  };

  const deleteCardProgram = async (orgId, cardProgramID) => {
    const result = await Orgs.deleteCardProgram(orgId, cardProgramID);

    const cardPrograms = state.cardPrograms.filter((program) => program.ID !== cardProgramID);

    setState({
      ...state,
      cardPrograms,
    });

    return result;
  };

  const loadOrg = () => {
    if (!orgID) {
      setState({ ...state, loading: false });
      return;
    }

    Orgs.getByID(orgID).then(({ org, users, ccs }) => {
      const {
        ID,
        name,
        timeZone,
        currencyCode,
        fiscalYearStartDate,
        shippingAddress,
        cardPrograms,
        kycAMLComplete,
        status,
        partnerFields,
      } = org;
      const cardProgram = cardPrograms && cardPrograms[0] && cardPrograms[0].comdataID;
      const isComplete =
        ID &&
        users > 0 &&
        ccs.length > 0 &&
        cardProgram &&
        cardProgram.customerID &&
        kycAMLComplete;

      const successfullyCreated = sessionStorage.getItem("org-create-success");

      if (successfullyCreated) {
        sessionStorage.removeItem("org-create-success");
      }

      Toggles.getComplete(ID).then((toggles) => {
        setState({
          ...state,
          loading: false,
          complete: isComplete,
          orgData: {
            ID,
            name,
            status,
            timeZone,
            currencyCode,
            fiscalYearStartDate,
            partnerFields,
          },
          address: shippingAddress || {},
          cardPrograms: cardPrograms || [],
          users,
          ccs,
          toggles,
          done: successfullyCreated ? `Organization "${successfullyCreated}" created` : false,
        });
      });
    });
  };

  useEffect(loadOrg, [setState]);

  const mappingPartnerFields = (fields) => {
    const organization = fields?.spotnana?.organizationNode?.organization;

    if (!organization) {
      return fields;
    }

    const emergencyContacts = organization.emergencyContactInfos?.map((contact) => ({
      firstName: contact.name.given,
      middleName: contact.name.middle,
      lastName: contact.name.family1,
      designation: contact.designation,
      email: contact.email,
      phone: `${contact.phoneNumber.countryCode}${contact?.phoneNumber?.rawInput}`,
    }));
    return {
      ...fields,
      spotnana: {
        ...fields.spotnana,
        emergencyContacts,
        emailDomains: organization.emailDomains,
        orgName: organization.name,
      },
    };
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });

    const data = { ...state.orgData, shippingAddress: state.address };
    const { partnerFields } = state.orgData;
    const partnerFieldsArr =
      typeof partnerFields === "object" ? Object.values(mappingPartnerFields(partnerFields)) : [];

    partnerFieldsArr.forEach((partnerField) => {
      data.partnerFields[partnerField.name] = {
        name: partnerField.name,
        orgID: partnerField.orgID,
      };
      if (partnerField.name === "spotnana" && partnerField.legalEntityID && partnerField.orgID) {
        data.partnerFields[partnerField.name].legalEntityID = partnerField.legalEntityID;
        data.partnerFields[partnerField.name].spotnanaData = {
          name: partnerField.name,
          orgID: partnerField.orgID,
          emailDomains: partnerField.emailDomains,
          emergencyContacts: partnerField.emergencyContacts,
          orgName: partnerField.orgName,
          legalEntityID: partnerField.legalEntityID,
          action: "update",
        };
      }
    });

    const result = await Orgs.submit(data);

    const errorMessage = result && result.errorMessage;

    if (errorMessage) {
      return setState({
        ...state,
        error: errorMessage || false,
        loading: false,
      });
    }

    loadOrg();

    if (!state.orgData.ID) {
      sessionStorage.setItem("org-create-success", state.orgData.name);
      window.location = `/edit/${result.ID}`;
      return null;
    }

    return setState({
      ...state,
      done: `Organization "${state.orgData.name}" updated`,
      loading: false,
    });
  };

  const orgIDToClipboard = () => navigator.clipboard.writeText(state.orgData.ID);

  const {
    orgData,
    address,
    users,
    ccs,
    toggles,
    showCP,
    showCC,
    showUser,
    showToggles,
    showTMCs,
    showPhoneField,
    showPolicyRunOptions,
  } = state;

  const onAddPartnerField = (partnerFieldName) => {
    setState({ ...state, showPhoneField: partnerFieldName === "spotnana" });
  };
  const renderAdditional = () => {
    if (!orgData.ID) {
      return "";
    }

    const isReviewNeeded = state.cardPrograms.find((program) => isNull(program.isPrefunded));

    return (
      <div>
        <div className="show-toggle show-cp-toggle">
          <a
            id="cp-toggle"
            href="#cp-toggle"
            onClick={() => setState({ ...state, showCP: !showCP })}
          >
            {showCP ? "Hide" : "Show"} Card Program Section
          </a>
          {isReviewNeeded ? (
            <div className="icon-wrapper-tooltip">
              <Icon
                icon={exclamationCircleOutlined}
                style={{
                  color: Colors.danger_red,
                  fontSize: "1.4rem",
                  marginLeft: "0.5rem",
                  verticalAlign: "sub",
                }}
              />
              <div className="tooltip">Needs review for card program</div>
            </div>
          ) : null}
        </div>
        <CardProgram
          show={showCP}
          orgID={orgData.ID}
          cardPrograms={state.cardPrograms}
          addCardProgram={addCardProgram}
          deleteCardProgram={deleteCardProgram}
        />
        <div className="show-toggle show-cc-toggle">
          <a
            id="cc-toggle"
            href="#cc-toggle"
            onClick={() => setState({ ...state, showCC: !showCC })}
          >
            {showCC ? "Hide" : "Show"} Cost Center Section
          </a>
        </div>
        <CostCenter
          show={showCC}
          orgID={orgData.ID}
          ccs={ccs}
          addCC={addCC}
          orgName={orgData.name}
        />
        <div className="show-toggle show-user-toggle">
          <a
            id="user-toggle"
            href="#user-toggle"
            onClick={() => setState({ ...state, showUser: !showUser })}
          >
            {showUser ? "Hide" : "Show"} User Section
          </a>
        </div>
        <User
          show={showUser}
          orgID={orgData.ID}
          orgName={orgData.name}
          ccs={ccs}
          address={address}
          users={users}
          addUser={addUser}
          addCommPrefs={addCommPrefs}
        />
        <div className="show-toggle show-toggles-toggle">
          <a
            id="toggles-toggle"
            href="#toggles-toggle"
            onClick={() => setState({ ...state, showToggles: !showToggles })}
          >
            {showToggles ? "Hide" : "Show"} Feature Toggles Section
          </a>
        </div>
        <FeatureToggles
          show={showToggles}
          orgID={orgData.ID}
          toggles={toggles}
          patchToggle={Toggles.patchToggle}
        />
        <div className="show-tmc show-tmcs-toggle">
          <a
            id="tmcs-toggle"
            href="#tmcs-toggle"
            onClick={() => setState({ ...state, showTMCs: !showTMCs })}
          >
            {showTMCs ? "Hide" : "Show"} Partner Integration Section
          </a>
        </div>
        <TravelManagementCompanies
          show={showTMCs}
          orgData={orgData}
          onSubmit={onSubmit}
          loadOrg={loadOrg}
          formRef={formRef}
          onAddPartnerField={onAddPartnerField}
          address={state.address}
        />
        <div>
          <a
            id="expense-policy-run-toggle"
            href="#expense-policy-run-toggle"
            onClick={() =>
              setState({
                ...state,
                showPolicyRunOptions: !showPolicyRunOptions,
              })
            }
          >
            Policy Run
          </a>
        </div>
        {showPolicyRunOptions && <PolicyRunOptions orgID={orgData.ID} orgName={orgData.name} />}
      </div>
    );
  };

  return (
    <div className="org-edit">
      <div className="org-edit-head">
        <h3>{orgID ? "Manage" : "Create"} Org</h3>
        <a href="/list">Back</a>
      </div>
      <Error error={state.error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={state.done} onDismiss={() => setState({ ...state, done: false })} />
      <Loader loading={state.loading}>
        <div className="org-edit-body">
          <div className="org-edit-form">
            <form onSubmit={onSubmit} ref={formRef}>
              <div className="form-part base-info">
                <div className="form-part-inner-left">
                  <p>Org Info</p>
                  {orgID ? (
                    <div>
                      <label htmlFor="edit-org-id">
                        Org ID
                        <input id="edit-org-id" type="text" readOnly value={orgData.ID} />
                        <button className="copy-org-id" type="button" onClick={orgIDToClipboard}>
                          Copy
                        </button>
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                  <div>
                    <label htmlFor="edit-org-name">
                      Org Name *
                      <input
                        id="edit-org-name"
                        type="text"
                        required
                        maxLength="30"
                        value={orgData.name}
                        onChange={processInput("orgData", "name")}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-time-zone">
                      Time Zone *
                      <input
                        id="edit-org-time-zone"
                        type="text"
                        readOnly
                        value={orgData.timeZone}
                        onChange={processInput("orgData", "timeZone")}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-currency" className="cur-label">
                      Currency Code *
                      <Select
                        id="edit-org-currency"
                        className="cur-selector"
                        options={currencyCodes}
                        defaultValue={{
                          label: orgData.currencyCode
                            ? find(currencyCodes, (cc) => cc.value === orgData.currencyCode).label
                            : currencyCodes[0].label,
                          value: orgData.currencyCode || currencyCodes[0].value,
                        }}
                        onChange={(e) =>
                          setState({
                            ...state,
                            orgData: {
                              ...state.orgData,
                              currencyCode: e.value,
                            },
                          })
                        }
                        isSearchable={false}
                        styles={customStyles(354)}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-fiscal-year">
                      Fiscal Year Start Date
                      <input
                        id="edit-org-fiscal-year"
                        type="date"
                        value={orgData.fiscalYearStartDate || ""}
                        onChange={processInput("orgData", "fiscalYearStartDate")}
                      />
                    </label>
                  </div>
                </div>
              </div>
              <div className="form-separator" />
              <div className="form-part address">
                <div className="form-part-inner-right">
                  <p>Shipping Address</p>
                  <div>
                    <label htmlFor="edit-org-address-address">
                      Address *
                      <input
                        id="edit-org-address-address"
                        type="text"
                        required
                        value={address.address1}
                        onChange={processInput("address", "address1")}
                      />
                    </label>
                  </div>
                  <div>
                    <input
                      id="edit-org-address-address2"
                      type="text"
                      value={address.address2}
                      onChange={processInput("address", "address2")}
                    />
                  </div>
                  <div>
                    <label htmlFor="edit-org-address-city">
                      City *
                      <input
                        id="edit-org-address-city"
                        type="text"
                        required
                        value={address.city}
                        onChange={processInput("address", "city")}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-address-zip">
                      State *
                      <input
                        id="edit-org-address-zip"
                        type="text"
                        required
                        value={address.state}
                        onChange={processInput("address", "state")}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-address-zip">
                      Postal Code *
                      <input
                        id="edit-org-address-zip"
                        type="text"
                        required
                        value={address.postalCode}
                        onChange={processInput("address", "postalCode")}
                      />
                    </label>
                  </div>
                  <div>
                    <label htmlFor="edit-org-address-country">
                      Country *
                      <input
                        id="edit-org-address-country"
                        type="text"
                        required
                        value={address.country}
                        onChange={processInput("address", "country")}
                      />
                    </label>
                  </div>
                  {showPhoneField || orgData.partnerFields.spotnana ? (
                    <>
                      <div>
                        <label htmlFor="edit-org-phone-number">
                          Phone number *
                          <input
                            id="edit-org-phone-number"
                            type="phone"
                            required
                            pattern="^[0-9]{11}"
                            placeholder="Phone with Country Code without +"
                            value={address.phone}
                            onChange={processInput("address", "phone")}
                          />
                        </label>
                      </div>
                      <div>
                        <label htmlFor="edit-org-tax-id">
                          Tax ID Number
                          <input
                            id="edit-org-tax-id"
                            type="text"
                            value={address.taxID}
                            onChange={processInput("address", "taxID")}
                          />
                        </label>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="submit">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
          {renderAdditional()}
        </div>
      </Loader>
    </div>
  );
}
