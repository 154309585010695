import { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";
import customStyles from "./selectorStyle";

export default function User(props) {
  const { show, orgID, ccs, users, address, addUser, addCommPrefs, orgName } = props;
  const initialState = {
    loading: false,
    error: false,
    done: false,
    userData: {
      orgID,
      loginID: "",
      password: "",
      emailAddress: "",
      lastName: "Support",
      firstName: "Center",
      roles: ["FM"],
      costCenterIDs: [],
      defaultCostCenterID: "",
      billingAddress: {
        address: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country,
      },
    },
  };

  const [state, setState] = useState(initialState);

  const setUser = (data) => {
    const userData = { ...state.userData };

    Object.keys(data).forEach((key) => {
      userData[key] = data[key];
    });

    setState({ ...state, userData });
  };

  const setAddress = (data) => {
    const userData = { ...state.userData };

    Object.keys(data).forEach((key) => {
      userData.billingAddress[key] = data[key];
    });

    setState({ ...state, userData });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
      ...state.userData,
      costCenterIDs: state.userData.costCenterIDs.map(({ value }) => value),
      defaultCostCenterID: state.userData.defaultCostCenterID.value,
    };

    setState({ ...state, loading: true });
    const result = await addUser(data);

    if (result && result.ID) {
      setState({
        ...initialState,
        done: `User "${data.loginID}" created`,
      });

      const commPrefs = {
        userID: result.ID,
        emails: {
          optOut: true,
          alternateEmail: null,
        },
        pushNotifications: {
          optOut: true,
        },
      };

      const commPrefsResult = await addCommPrefs(orgID, commPrefs);
      const errorMessage = commPrefsResult && commPrefsResult.errorMessage;

      if (errorMessage) {
        setState({ ...state, error: errorMessage, loading: false });
      }

      return;
    }

    const errorMessage = result && result.errorMessage;
    setState({ ...state, error: errorMessage, loading: false });
  };

  const { loading, error, done, userData } = state;
  const { billingAddress } = userData;
  const options = ccs.map((cc) => ({ value: cc.ID, label: cc.name }));

  if (!show) {
    return "";
  }

  const renderBody = () => {
    if (users > 0) {
      return (
        <div className="new-user-body">
          Org already has {users} user{users > 1 ? "s" : ""}.
        </div>
      );
    }

    return (
      <div className="new-user-body">
        <div className="new-user-form">
          <div className="new-user-info">
            Create Center Support user with FM role for {orgName} organization.
          </div>
          <form onSubmit={onSubmit} autoComplete="off">
            <div className="form-part user-data">
              <div className="form-part-inner-left">
                <div className="data-input">
                  <label htmlFor="new-user-fname">
                    First name *
                    <input
                      id="new-user-fname"
                      type="text"
                      required
                      value={userData.firstName}
                      onChange={(e) => setUser({ firstName: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-lname">
                    Last name *
                    <input
                      id="new-user-lname"
                      type="text"
                      required
                      value={userData.lastName}
                      onChange={(e) => setUser({ lastName: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-login">
                    Login ID *
                    <input
                      id="new-user-login"
                      type="text"
                      required
                      value={userData.loginID}
                      onChange={(e) =>
                        setUser({
                          loginID: e.target.value,
                          emailAddress: e.target.value,
                        })
                      }
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-password">
                    Password *
                    <input
                      id="new-user-password"
                      type="text"
                      required
                      value={userData.password}
                      onChange={(e) => setUser({ password: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input parent-cc">
                  <label>
                    Cost Centers *
                    <Select
                      className="cc-selector"
                      required
                      isMulti
                      isSearchable={false}
                      options={options}
                      value={userData.costCenterIDs}
                      onChange={(val) => setUser({ costCenterIDs: val })}
                      styles={customStyles()}
                    />
                  </label>
                </div>
                <div className="data-input parent-cc">
                  <label>
                    Default Cost Center *
                    <Select
                      className="cc-selector"
                      required
                      options={options}
                      isSearchable={false}
                      value={userData.defaultCostCenterID}
                      onChange={(val) => setUser({ defaultCostCenterID: val })}
                      styles={customStyles()}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="form-separator" />
            <div className="form-part user-address">
              <div className="form-part-inner-right">
                <div className="data-input">
                  <label htmlFor="new-user-address-address">
                    Address
                    <input
                      id="new-user-address-address"
                      type="text"
                      value={billingAddress.address}
                      onChange={(e) => setAddress({ address: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <input
                    id="new-user-address-address2"
                    type="text"
                    value={billingAddress.address2}
                    onChange={(e) => setAddress({ address2: e.target.value })}
                  />
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-address-city">
                    City *
                    <input
                      id="new-user-address-city"
                      type="text"
                      required
                      value={billingAddress.city}
                      onChange={(e) => setAddress({ city: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-address-state">
                    State *
                    <input
                      id="new-user-address-state"
                      type="text"
                      required
                      value={billingAddress.state}
                      onChange={(e) => setAddress({ state: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-address-zip">
                    Postal Code
                    <input
                      id="new-user-address-zip"
                      type="text"
                      value={billingAddress.postalCode}
                      onChange={(e) => setAddress({ postalCode: e.target.value })}
                    />
                  </label>
                </div>
                <div className="data-input">
                  <label htmlFor="new-user-address-country">
                    Country *
                    <input
                      id="new-user-address-country"
                      type="text"
                      required
                      value={billingAddress.country}
                      onChange={(e) => setAddress({ country: e.target.value })}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="data-input submit">
              <button type="submit" disabled={loading}>
                Add User
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Loader loading={loading}>
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      {renderBody()}
    </Loader>
  );
}

User.propTypes = {
  show: PropTypes.bool,
  orgID: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  ccs: PropTypes.array,
  users: PropTypes.number.isRequired,
  address: PropTypes.object.isRequired,
  addUser: PropTypes.func.isRequired,
  addCommPrefs: PropTypes.func.isRequired,
};
