import "./Success.css";

export default function Success({ message, onDismiss }) {
  const dismiss = (e) => {
    e.preventDefault();
    onDismiss();
  };

  if (message) {
    return (
      <div className="success-body">
        <button type="button" className="dismiss-success" onClick={dismiss}>
          Dismiss
        </button>
        <div className="success-message">
          <p>{message}</p>
        </div>
      </div>
    );
  }

  return "";
}
