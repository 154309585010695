import "./Error.css";

export default function Error({ error, onDismiss }) {
  const dismiss = (e) => {
    e.preventDefault();
    onDismiss();
  };

  if (error) {
    return (
      <div className="error-body">
        <button type="button" className="dismiss-error" onClick={dismiss}>
          Dismiss
        </button>
        <div className="error-message">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return "";
}
