import { useState } from "react";
import PropTypes from "prop-types";

import { runPolicyForOrg } from "../../services/utils";
import Error from "../Error";
import Loader from "../Loader";
import Success from "../Success";

const StatusList = [
  {
    value: "unsubmitted",
    label: "Unsubmitted",
  },
  {
    value: "readyToSubmit",
    label: "Ready To Submit",
  },
  {
    value: "submitted",
    label: "Submitted (In Review)",
  },
  {
    value: "approved",
    label: "Approved (Reviewed)",
  },
  {
    value: "rejected",
    label: "Denied (Reviewed)",
  },
  {
    value: "readyToPost",
    label: "Archived",
  },
];

const RuleTypeList = [
  {
    value: "approval",
    label: "Approval rules",
  },
  {
    value: "mapping",
    label: "Mapping rules",
  },
  {
    value: "flags",
    label: "Flags",
  },
];

export default function PolicyRunOptions(props) {
  const { orgID, orgName } = props;
  const initialState = {
    days: 66,
    status: [
      "pendingSettlement",
      "unsubmitted",
      "readyToSubmit",
      "submitted",
      "approved",
      "rejected",
      "readyToPost",
    ],
    rules: ["approval", "mapping", "flags"],
  };

  const [state, setState] = useState(initialState);

  const changeStatus = (value, isChecked) => {
    let newValues = state.status;
    if (isChecked) {
      if (!newValues.includes(value)) {
        newValues.push(value);
        if (value === "unsubmitted") newValues.push("pendingSettlement");
      }
    } else {
      newValues = newValues.filter((item) => {
        const removedValue = [value];
        if (value === "unsubmitted") {
          removedValue.push("pendingSettlement");
        }
        return !removedValue.includes(item);
      });
    }
    setState({
      ...state,
      status: newValues,
    });
  };

  const changeRuleType = (value, isChecked) => {
    let newValues = state.rules;
    if (isChecked) {
      if (!newValues.includes(value)) {
        newValues.push(value);
      }
    } else {
      newValues = newValues.filter((item) => item !== value);
    }
    setState({
      ...state,
      rules: newValues,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setState({ ...state, loading: true });
    const { days, status, rules } = state;
    const result = await runPolicyForOrg(orgID, { days, status, rules });

    if (result.status === 204) {
      setState({
        ...state,
        done: `Policy was run for "${orgName}" org`,
      });
      return;
    }

    const errorMessage = result && result.errorMessage;
    setState({ ...state, error: errorMessage, loading: false });
  };

  const { loading, error, done } = state;

  return (
    <Loader loading={loading}>
      <Error error={error} onDismiss={() => setState({ ...state, error: false })} />
      <Success message={done} onDismiss={() => setState({ ...state, done: false })} />
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="days">
            Number of days to run *
            <input
              id="days"
              type="number"
              name="days"
              required
              value={state.days}
              onChange={(e) => setState({ ...state, days: Number(e.target.value) })}
            />
          </label>
        </div>
        <div className="form-group">
          <h4 className="group-title">Types of Expenses to include:</h4>
          <ul className="clear-list">
            {StatusList.map((item) => (
              <li className="data-input" key={item.value}>
                <label htmlFor={item.value}>
                  <input
                    id={item.value}
                    className="small"
                    type="checkbox"
                    name="status"
                    value={item.value}
                    checked={state.status.includes(item.value)}
                    onChange={(e) => changeStatus(item.value, e.target.checked)}
                  />
                  {item.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="form-group">
          <h4 className="group-title">Rule types:</h4>
          <ul className="clear-list">
            {RuleTypeList.map((item) => (
              <li className="data-input" key={item.value}>
                <label htmlFor={item.value}>
                  <input
                    id={item.value}
                    className="small"
                    type="checkbox"
                    name="rules"
                    value={item.value}
                    checked={state.rules.includes(item.value)}
                    onChange={(e) => changeRuleType(item.value, e.target.checked)}
                  />
                  {item.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="data-input submit">
          <button type="submit">Run Policy</button>
        </div>
      </form>
    </Loader>
  );
}

PolicyRunOptions.propTypes = {
  orgID: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};
