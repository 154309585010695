export default function Loader({ loading, children }) {
  if (loading) {
    return (
      <div className="loader">
        <img alt="loading" src="/duck-loading.gif" width="100px" />
      </div>
    );
  }

  return children;
}
